import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as impersonatevXjnNUkafCMeta } from "/opt/buildhome/repo/pages/auth/impersonate.vue?macro=true";
import { default as indexzLfJUIfcqEMeta } from "/opt/buildhome/repo/pages/auth/login/index.vue?macro=true";
import { default as index7pdB5lzGs8Meta } from "/opt/buildhome/repo/pages/auth/login/magic-link/index.vue?macro=true";
import { default as validateAqt0xFRKzfMeta } from "/opt/buildhome/repo/pages/auth/login/magic-link/validate.vue?macro=true";
import { default as logoutgV6ePTKB4cMeta } from "/opt/buildhome/repo/pages/auth/logout.vue?macro=true";
import { default as index1zmBTjb2UbMeta } from "/opt/buildhome/repo/pages/auth/register/index.vue?macro=true";
import { default as tenantpMio6kngjSMeta } from "/opt/buildhome/repo/pages/auth/register/tenant.vue?macro=true";
import { default as cancelled2fNEZS7iPNMeta } from "/opt/buildhome/repo/pages/auth/tenant/[id]/cancelled.vue?macro=true";
import { default as inactive9Vd5YMGt3AMeta } from "/opt/buildhome/repo/pages/auth/tenant/[id]/inactive.vue?macro=true";
import { default as errorXJyW8cIsI8Meta } from "/opt/buildhome/repo/pages/auth/tenant/error.vue?macro=true";
import { default as authorizedRzjuqqfYLDMeta } from "/opt/buildhome/repo/pages/bankaccount/authorized.vue?macro=true";
import { default as authorizingGsFMCe5fhCMeta } from "/opt/buildhome/repo/pages/bankaccount/authorizing.vue?macro=true";
import { default as _91bankAccount_93plSqqpKVPHMeta } from "/opt/buildhome/repo/pages/bankaccount/bank-accounts/[bankAccount].vue?macro=true";
import { default as indexkHLYamu8ebMeta } from "/opt/buildhome/repo/pages/bankaccount/bank-accounts/index.vue?macro=true";
import { default as indexc6q13wlq0SMeta } from "/opt/buildhome/repo/pages/calendar/index.vue?macro=true";
import { default as activitiespGzTAuhgefMeta } from "/opt/buildhome/repo/pages/crm/activities.vue?macro=true";
import { default as indexoSf6DeYtrnMeta } from "/opt/buildhome/repo/pages/crm/lead/[id]/index.vue?macro=true";
import { default as leadseHvYvtgeD2Meta } from "/opt/buildhome/repo/pages/crm/leads.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as index2BuXNsa4scMeta } from "/opt/buildhome/repo/pages/invoicing/contacts/index.vue?macro=true";
import { default as editaoJyrfmZb4Meta } from "/opt/buildhome/repo/pages/invoicing/credit-notes/[id]/edit.vue?macro=true";
import { default as createBeshCUqXLiMeta } from "/opt/buildhome/repo/pages/invoicing/credit-notes/create.vue?macro=true";
import { default as index6L6wtNq0h5Meta } from "/opt/buildhome/repo/pages/invoicing/credit-notes/index.vue?macro=true";
import { default as edit9MsipJjs8KMeta } from "/opt/buildhome/repo/pages/invoicing/delivery-slips/[id]/edit.vue?macro=true";
import { default as create1zURC1xnsCMeta } from "/opt/buildhome/repo/pages/invoicing/delivery-slips/create.vue?macro=true";
import { default as indexQb5FCN7ReLMeta } from "/opt/buildhome/repo/pages/invoicing/delivery-slips/index.vue?macro=true";
import { default as editBSLdrfcihoMeta } from "/opt/buildhome/repo/pages/invoicing/expenses/[id]/edit.vue?macro=true";
import { default as createKO4yLSETgpMeta } from "/opt/buildhome/repo/pages/invoicing/expenses/create.vue?macro=true";
import { default as indexgHzdYTW9FpMeta } from "/opt/buildhome/repo/pages/invoicing/expenses/index.vue?macro=true";
import { default as documents8OglKsww6sMeta } from "/opt/buildhome/repo/pages/invoicing/export/documents.vue?macro=true";
import { default as indexCD7jY1zieiMeta } from "/opt/buildhome/repo/pages/invoicing/export/index.vue?macro=true";
import { default as documentsCjcHiabzhOMeta } from "/opt/buildhome/repo/pages/invoicing/import/documents.vue?macro=true";
import { default as indexOwHQ6f38jQMeta } from "/opt/buildhome/repo/pages/invoicing/import/index.vue?macro=true";
import { default as indexTh4KMj2ldWMeta } from "/opt/buildhome/repo/pages/invoicing/index.vue?macro=true";
import { default as indexGyA3frfBmDMeta } from "/opt/buildhome/repo/pages/invoicing/inventory/index.vue?macro=true";
import { default as product_45categoriesVxjbEJpScWMeta } from "/opt/buildhome/repo/pages/invoicing/inventory/product-categories.vue?macro=true";
import { default as product_45sectionsN8TIOWqkI1Meta } from "/opt/buildhome/repo/pages/invoicing/inventory/product-sections.vue?macro=true";
import { default as product_45unitsEnzYe18Mh2Meta } from "/opt/buildhome/repo/pages/invoicing/inventory/product-units.vue?macro=true";
import { default as productsa6kU7uslH1Meta } from "/opt/buildhome/repo/pages/invoicing/inventory/products.vue?macro=true";
import { default as vat_45ratesVag8VXBqzXMeta } from "/opt/buildhome/repo/pages/invoicing/inventory/vat-rates.vue?macro=true";
import { default as editW6xAwDxn7IMeta } from "/opt/buildhome/repo/pages/invoicing/invoices/[id]/edit.vue?macro=true";
import { default as createiEil0DwTD8Meta } from "/opt/buildhome/repo/pages/invoicing/invoices/create.vue?macro=true";
import { default as indexhTOKCQAlgKMeta } from "/opt/buildhome/repo/pages/invoicing/invoices/index.vue?macro=true";
import { default as edit3FArniALVdMeta } from "/opt/buildhome/repo/pages/invoicing/order-slips/[id]/edit.vue?macro=true";
import { default as create1FDJjQNowIMeta } from "/opt/buildhome/repo/pages/invoicing/order-slips/create.vue?macro=true";
import { default as indexhK5ihVASGVMeta } from "/opt/buildhome/repo/pages/invoicing/order-slips/index.vue?macro=true";
import { default as edit4sIjI7dR1TMeta } from "/opt/buildhome/repo/pages/invoicing/quotes/[id]/edit.vue?macro=true";
import { default as createvH3pHKD78jMeta } from "/opt/buildhome/repo/pages/invoicing/quotes/create.vue?macro=true";
import { default as indexQ8NbMEB6WzMeta } from "/opt/buildhome/repo/pages/invoicing/quotes/index.vue?macro=true";
import { default as annex_45pdfkJ3zquMWX3Meta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/annex-pdf.vue?macro=true";
import { default as credit_45notesuNHhIj3OFAMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/credit-notes.vue?macro=true";
import { default as delivery_45slipsYkHSWC0c1qMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/delivery-slips.vue?macro=true";
import { default as indexpv6QNsXQ43Meta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/index.vue?macro=true";
import { default as invoicesz4ivqHixjGMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/invoices.vue?macro=true";
import { default as order_45slipsI4sWKopZhGMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/order-slips.vue?macro=true";
import { default as quotesADPNVJ5sYDMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents/quotes.vue?macro=true";
import { default as documentssCOgnpuZTtMeta } from "/opt/buildhome/repo/pages/invoicing/settings/documents.vue?macro=true";
import { default as indexEZ16Z4VdlHMeta } from "/opt/buildhome/repo/pages/invoicing/settings/index.vue?macro=true";
import { default as documentsftlN68qBj4Meta } from "/opt/buildhome/repo/pages/invoicing/settings/notifications/documents.vue?macro=true";
import { default as indexoQtEmQWzlhMeta } from "/opt/buildhome/repo/pages/invoicing/settings/notifications/index.vue?macro=true";
import { default as payment_45remindersxh3SmnIjIGMeta } from "/opt/buildhome/repo/pages/invoicing/settings/notifications/payment-reminders.vue?macro=true";
import { default as notificationsAtoi33PqCQMeta } from "/opt/buildhome/repo/pages/invoicing/settings/notifications.vue?macro=true";
import { default as templates7kE5N9xGxIMeta } from "/opt/buildhome/repo/pages/invoicing/settings/templates.vue?macro=true";
import { default as settingskX8BxbY2lfMeta } from "/opt/buildhome/repo/pages/invoicing/settings.vue?macro=true";
import { default as statisticsYiREiy5UFfMeta } from "/opt/buildhome/repo/pages/invoicing/statistics.vue?macro=true";
import { default as indexCSx38ydJwoMeta } from "/opt/buildhome/repo/pages/onboarding/index.vue?macro=true";
import { default as indexe7nOpqdrhmMeta } from "/opt/buildhome/repo/pages/planning/index.vue?macro=true";
import { default as indexSsD3jPanxrMeta } from "/opt/buildhome/repo/pages/redirect/index.vue?macro=true";
import { default as contact_45informationsjY97flgxNvMeta } from "/opt/buildhome/repo/pages/settings/company/contact-informations.vue?macro=true";
import { default as indexRgXymITI7LMeta } from "/opt/buildhome/repo/pages/settings/company/index.vue?macro=true";
import { default as informationsqsYfbU20dJMeta } from "/opt/buildhome/repo/pages/settings/company/informations.vue?macro=true";
import { default as logocxzI0x22g2Meta } from "/opt/buildhome/repo/pages/settings/company/logo.vue?macro=true";
import { default as time_45activitiesNLFoiB2iNaMeta } from "/opt/buildhome/repo/pages/settings/company/time-activities.vue?macro=true";
import { default as indexNDbLfjd3sGMeta } from "/opt/buildhome/repo/pages/settings/outbox/index.vue?macro=true";
import { default as logspL7xjKWCP4Meta } from "/opt/buildhome/repo/pages/settings/outbox/logs.vue?macro=true";
import { default as settingsjcb1VAKR04Meta } from "/opt/buildhome/repo/pages/settings/outbox/settings.vue?macro=true";
import { default as avatarv0hEfZlEkXMeta } from "/opt/buildhome/repo/pages/settings/profile/avatar.vue?macro=true";
import { default as indexPiLoyVOKUxMeta } from "/opt/buildhome/repo/pages/settings/profile/index.vue?macro=true";
import { default as informationsIieTmrUaUFMeta } from "/opt/buildhome/repo/pages/settings/profile/informations.vue?macro=true";
import { default as passwordmbF8skaxCBMeta } from "/opt/buildhome/repo/pages/settings/profile/password.vue?macro=true";
import { default as creditszgMmwAVxGdMeta } from "/opt/buildhome/repo/pages/settings/subscription/credits.vue?macro=true";
import { default as indexUSrknHIjvDMeta } from "/opt/buildhome/repo/pages/settings/subscription/index.vue?macro=true";
import { default as invoices030dy7WnRbMeta } from "/opt/buildhome/repo/pages/settings/subscription/invoices.vue?macro=true";
import { default as planSRA4un6QBQMeta } from "/opt/buildhome/repo/pages/settings/subscription/plan.vue?macro=true";
import { default as trialTF5cMdPXx1Meta } from "/opt/buildhome/repo/pages/settings/subscription/trial.vue?macro=true";
import { default as users_45spotso9nHWnpi0IMeta } from "/opt/buildhome/repo/pages/settings/subscription/users-spots.vue?macro=true";
import { default as index0RFa8FiYGwMeta } from "/opt/buildhome/repo/pages/settings/terms-of-service/index.vue?macro=true";
import { default as _91id_93M2jjnvBI9MMeta } from "/opt/buildhome/repo/pages/settings/users/[id].vue?macro=true";
import { default as indexXu5KbXbpH1Meta } from "/opt/buildhome/repo/pages/settings/users/index.vue?macro=true";
import { default as inviteBHz5kTKFLGMeta } from "/opt/buildhome/repo/pages/settings/users/invite.vue?macro=true";
import { default as signed_45documentRTdi9XGOffMeta } from "/opt/buildhome/repo/pages/signed-document.vue?macro=true";
import { default as switch_45tenant1FNuV8MzJDMeta } from "/opt/buildhome/repo/pages/switch-tenant.vue?macro=true";
import { default as indexpHxEomLDMNMeta } from "/opt/buildhome/repo/pages/task/index.vue?macro=true";
import { default as indexDsSxFIQFZDMeta } from "/opt/buildhome/repo/pages/timesheet/index.vue?macro=true";
import { default as indexFKB6mfZqwjMeta } from "/opt/buildhome/repo/pages/worksite/index.vue?macro=true";
import { default as indexO9QXRgvd0tMeta } from "/opt/buildhome/repo/pages/worksite/planning/index.vue?macro=true";
import { default as worksite2gnggKcLlPMeta } from "/opt/buildhome/repo/pages/worksite/planning/worksite.vue?macro=true";
import { default as planningjoDwLkCKtzMeta } from "/opt/buildhome/repo/pages/worksite/planning.vue?macro=true";
import { default as profitabilityJzeo7J3W89Meta } from "/opt/buildhome/repo/pages/worksite/profitability.vue?macro=true";
import { default as worksite_45closure_45reportAGqnbJ5PlyMeta } from "/opt/buildhome/repo/pages/worksite/worksite-closure-report.vue?macro=true";
import { default as indexK2reUkBHHAMeta } from "/opt/buildhome/repo/pages/worksite/worksite-closure-reports/index.vue?macro=true";
import { default as worksite_45progress_45billingsKIhEFQ6EYxMeta } from "/opt/buildhome/repo/pages/worksite/worksite-progress-billings.vue?macro=true";
import { default as documentspOZeplGFhXMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/documents.vue?macro=true";
import { default as indexUvMTKe2cIUMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/index.vue?macro=true";
import { default as informationsEjfiGk8tkDMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/informations.vue?macro=true";
import { default as profitability2VRWD1bV9gMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/profitability.vue?macro=true";
import { default as edit45KRA0Gz0hMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/[progressBillingId]/edit.vue?macro=true";
import { default as indexyUT62cJMMMMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/[progressBillingId]/index.vue?macro=true";
import { default as createIofurjNHnTMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/create.vue?macro=true";
import { default as indexgOKRyjAOFwMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/index.vue?macro=true";
import { default as resourcespkQjZCaveqMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/resources.vue?macro=true";
import { default as taskspCUmpySvy4Meta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/tasks.vue?macro=true";
import { default as time_45sheetsv7N62PXQXGMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/time-sheets.vue?macro=true";
import { default as timelinevVczYvnEf5Meta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/timeline.vue?macro=true";
import { default as indexniJSrfRoqPMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/worksite-closure-reports/[closureReport]/index.vue?macro=true";
import { default as indexjvp7Lbs7ZwMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id]/worksite-closure-reports/index.vue?macro=true";
import { default as _91id_939IIjKoADdpMeta } from "/opt/buildhome/repo/pages/worksite/worksite/[id].vue?macro=true";
import { default as worksitesD9JURVdQn4Meta } from "/opt/buildhome/repo/pages/worksite/worksites.vue?macro=true";
import { default as auth_45switchCmfR8XJR2AMeta } from "/opt/buildhome/repo/node_modules/@deegital/nuxt-trustup-io-app-switch/dist/runtime/pages/auth-switch?macro=true";
export default [
  {
    name: _91_46_46_46slug_93HxQxjzxhjNMeta?.name ?? "slug",
    path: _91_46_46_46slug_93HxQxjzxhjNMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93HxQxjzxhjNMeta || {},
    alias: _91_46_46_46slug_93HxQxjzxhjNMeta?.alias || [],
    redirect: _91_46_46_46slug_93HxQxjzxhjNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: impersonatevXjnNUkafCMeta?.name ?? "auth-impersonate",
    path: impersonatevXjnNUkafCMeta?.path ?? "/auth/impersonate",
    meta: impersonatevXjnNUkafCMeta || {},
    alias: impersonatevXjnNUkafCMeta?.alias || [],
    redirect: impersonatevXjnNUkafCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/impersonate.vue").then(m => m.default || m)
  },
  {
    name: indexzLfJUIfcqEMeta?.name ?? "auth-login",
    path: indexzLfJUIfcqEMeta?.path ?? "/auth/login",
    meta: indexzLfJUIfcqEMeta || {},
    alias: indexzLfJUIfcqEMeta?.alias || [],
    redirect: indexzLfJUIfcqEMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: index7pdB5lzGs8Meta?.name ?? "auth-login-magic-link",
    path: index7pdB5lzGs8Meta?.path ?? "/auth/login/magic-link",
    meta: index7pdB5lzGs8Meta || {},
    alias: index7pdB5lzGs8Meta?.alias || [],
    redirect: index7pdB5lzGs8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/login/magic-link/index.vue").then(m => m.default || m)
  },
  {
    name: validateAqt0xFRKzfMeta?.name ?? "auth-login-magic-link-validate",
    path: validateAqt0xFRKzfMeta?.path ?? "/auth/login/magic-link/validate",
    meta: validateAqt0xFRKzfMeta || {},
    alias: validateAqt0xFRKzfMeta?.alias || [],
    redirect: validateAqt0xFRKzfMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/login/magic-link/validate.vue").then(m => m.default || m)
  },
  {
    name: logoutgV6ePTKB4cMeta?.name ?? "auth-logout",
    path: logoutgV6ePTKB4cMeta?.path ?? "/auth/logout",
    meta: logoutgV6ePTKB4cMeta || {},
    alias: logoutgV6ePTKB4cMeta?.alias || [],
    redirect: logoutgV6ePTKB4cMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: index1zmBTjb2UbMeta?.name ?? "auth-register",
    path: index1zmBTjb2UbMeta?.path ?? "/auth/register",
    meta: index1zmBTjb2UbMeta || {},
    alias: index1zmBTjb2UbMeta?.alias || [],
    redirect: index1zmBTjb2UbMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: tenantpMio6kngjSMeta?.name ?? "auth-register-tenant",
    path: tenantpMio6kngjSMeta?.path ?? "/auth/register/tenant",
    meta: tenantpMio6kngjSMeta || {},
    alias: tenantpMio6kngjSMeta?.alias || [],
    redirect: tenantpMio6kngjSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/register/tenant.vue").then(m => m.default || m)
  },
  {
    name: cancelled2fNEZS7iPNMeta?.name ?? "auth-tenant-id-cancelled",
    path: cancelled2fNEZS7iPNMeta?.path ?? "/auth/tenant/:id()/cancelled",
    meta: cancelled2fNEZS7iPNMeta || {},
    alias: cancelled2fNEZS7iPNMeta?.alias || [],
    redirect: cancelled2fNEZS7iPNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/tenant/[id]/cancelled.vue").then(m => m.default || m)
  },
  {
    name: inactive9Vd5YMGt3AMeta?.name ?? "auth-tenant-id-inactive",
    path: inactive9Vd5YMGt3AMeta?.path ?? "/auth/tenant/:id()/inactive",
    meta: inactive9Vd5YMGt3AMeta || {},
    alias: inactive9Vd5YMGt3AMeta?.alias || [],
    redirect: inactive9Vd5YMGt3AMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/tenant/[id]/inactive.vue").then(m => m.default || m)
  },
  {
    name: errorXJyW8cIsI8Meta?.name ?? "auth-tenant-error",
    path: errorXJyW8cIsI8Meta?.path ?? "/auth/tenant/error",
    meta: errorXJyW8cIsI8Meta || {},
    alias: errorXJyW8cIsI8Meta?.alias || [],
    redirect: errorXJyW8cIsI8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/tenant/error.vue").then(m => m.default || m)
  },
  {
    name: authorizedRzjuqqfYLDMeta?.name ?? "bankaccount-authorized",
    path: authorizedRzjuqqfYLDMeta?.path ?? "/bankaccount/authorized",
    meta: authorizedRzjuqqfYLDMeta || {},
    alias: authorizedRzjuqqfYLDMeta?.alias || [],
    redirect: authorizedRzjuqqfYLDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bankaccount/authorized.vue").then(m => m.default || m)
  },
  {
    name: authorizingGsFMCe5fhCMeta?.name ?? "bankaccount-authorizing",
    path: authorizingGsFMCe5fhCMeta?.path ?? "/bankaccount/authorizing",
    meta: authorizingGsFMCe5fhCMeta || {},
    alias: authorizingGsFMCe5fhCMeta?.alias || [],
    redirect: authorizingGsFMCe5fhCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bankaccount/authorizing.vue").then(m => m.default || m)
  },
  {
    name: _91bankAccount_93plSqqpKVPHMeta?.name ?? "bankaccount-bank-accounts-bankAccount",
    path: _91bankAccount_93plSqqpKVPHMeta?.path ?? "/bankaccount/bank-accounts/:bankAccount()",
    meta: _91bankAccount_93plSqqpKVPHMeta || {},
    alias: _91bankAccount_93plSqqpKVPHMeta?.alias || [],
    redirect: _91bankAccount_93plSqqpKVPHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bankaccount/bank-accounts/[bankAccount].vue").then(m => m.default || m)
  },
  {
    name: indexkHLYamu8ebMeta?.name ?? "bankaccount-bank-accounts",
    path: indexkHLYamu8ebMeta?.path ?? "/bankaccount/bank-accounts",
    meta: indexkHLYamu8ebMeta || {},
    alias: indexkHLYamu8ebMeta?.alias || [],
    redirect: indexkHLYamu8ebMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bankaccount/bank-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexc6q13wlq0SMeta?.name ?? "calendar",
    path: indexc6q13wlq0SMeta?.path ?? "/calendar",
    meta: indexc6q13wlq0SMeta || {},
    alias: indexc6q13wlq0SMeta?.alias || [],
    redirect: indexc6q13wlq0SMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: activitiespGzTAuhgefMeta?.name ?? "crm-activities",
    path: activitiespGzTAuhgefMeta?.path ?? "/crm/activities",
    meta: activitiespGzTAuhgefMeta || {},
    alias: activitiespGzTAuhgefMeta?.alias || [],
    redirect: activitiespGzTAuhgefMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/crm/activities.vue").then(m => m.default || m)
  },
  {
    name: indexoSf6DeYtrnMeta?.name ?? "crm-lead-id",
    path: indexoSf6DeYtrnMeta?.path ?? "/crm/lead/:id()",
    meta: indexoSf6DeYtrnMeta || {},
    alias: indexoSf6DeYtrnMeta?.alias || [],
    redirect: indexoSf6DeYtrnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/crm/lead/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: leadseHvYvtgeD2Meta?.name ?? "crm-leads",
    path: leadseHvYvtgeD2Meta?.path ?? "/crm/leads",
    meta: leadseHvYvtgeD2Meta || {},
    alias: leadseHvYvtgeD2Meta?.alias || [],
    redirect: leadseHvYvtgeD2Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/crm/leads.vue").then(m => m.default || m)
  },
  {
    name: dashboard6cxkiptQlsMeta?.name ?? "dashboard",
    path: dashboard6cxkiptQlsMeta?.path ?? "/dashboard",
    meta: dashboard6cxkiptQlsMeta || {},
    alias: dashboard6cxkiptQlsMeta?.alias || [],
    redirect: dashboard6cxkiptQlsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index2BuXNsa4scMeta?.name ?? "invoicing-contacts",
    path: index2BuXNsa4scMeta?.path ?? "/invoicing/contacts",
    meta: index2BuXNsa4scMeta || {},
    alias: index2BuXNsa4scMeta?.alias || [],
    redirect: index2BuXNsa4scMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: editaoJyrfmZb4Meta?.name ?? "invoicing-credit-notes-id-edit",
    path: editaoJyrfmZb4Meta?.path ?? "/invoicing/credit-notes/:id()/edit",
    meta: editaoJyrfmZb4Meta || {},
    alias: editaoJyrfmZb4Meta?.alias || [],
    redirect: editaoJyrfmZb4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/credit-notes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createBeshCUqXLiMeta?.name ?? "invoicing-credit-notes-create",
    path: createBeshCUqXLiMeta?.path ?? "/invoicing/credit-notes/create",
    meta: createBeshCUqXLiMeta || {},
    alias: createBeshCUqXLiMeta?.alias || [],
    redirect: createBeshCUqXLiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/credit-notes/create.vue").then(m => m.default || m)
  },
  {
    name: index6L6wtNq0h5Meta?.name ?? "invoicing-credit-notes",
    path: index6L6wtNq0h5Meta?.path ?? "/invoicing/credit-notes",
    meta: index6L6wtNq0h5Meta || {},
    alias: index6L6wtNq0h5Meta?.alias || [],
    redirect: index6L6wtNq0h5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/credit-notes/index.vue").then(m => m.default || m)
  },
  {
    name: edit9MsipJjs8KMeta?.name ?? "invoicing-delivery-slips-id-edit",
    path: edit9MsipJjs8KMeta?.path ?? "/invoicing/delivery-slips/:id()/edit",
    meta: edit9MsipJjs8KMeta || {},
    alias: edit9MsipJjs8KMeta?.alias || [],
    redirect: edit9MsipJjs8KMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/delivery-slips/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create1zURC1xnsCMeta?.name ?? "invoicing-delivery-slips-create",
    path: create1zURC1xnsCMeta?.path ?? "/invoicing/delivery-slips/create",
    meta: create1zURC1xnsCMeta || {},
    alias: create1zURC1xnsCMeta?.alias || [],
    redirect: create1zURC1xnsCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/delivery-slips/create.vue").then(m => m.default || m)
  },
  {
    name: indexQb5FCN7ReLMeta?.name ?? "invoicing-delivery-slips",
    path: indexQb5FCN7ReLMeta?.path ?? "/invoicing/delivery-slips",
    meta: indexQb5FCN7ReLMeta || {},
    alias: indexQb5FCN7ReLMeta?.alias || [],
    redirect: indexQb5FCN7ReLMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/delivery-slips/index.vue").then(m => m.default || m)
  },
  {
    name: editBSLdrfcihoMeta?.name ?? "invoicing-expenses-id-edit",
    path: editBSLdrfcihoMeta?.path ?? "/invoicing/expenses/:id()/edit",
    meta: editBSLdrfcihoMeta || {},
    alias: editBSLdrfcihoMeta?.alias || [],
    redirect: editBSLdrfcihoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/expenses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createKO4yLSETgpMeta?.name ?? "invoicing-expenses-create",
    path: createKO4yLSETgpMeta?.path ?? "/invoicing/expenses/create",
    meta: createKO4yLSETgpMeta || {},
    alias: createKO4yLSETgpMeta?.alias || [],
    redirect: createKO4yLSETgpMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/expenses/create.vue").then(m => m.default || m)
  },
  {
    name: indexgHzdYTW9FpMeta?.name ?? "invoicing-expenses",
    path: indexgHzdYTW9FpMeta?.path ?? "/invoicing/expenses",
    meta: indexgHzdYTW9FpMeta || {},
    alias: indexgHzdYTW9FpMeta?.alias || [],
    redirect: indexgHzdYTW9FpMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/expenses/index.vue").then(m => m.default || m)
  },
  {
    name: documents8OglKsww6sMeta?.name ?? "invoicing-export-documents",
    path: documents8OglKsww6sMeta?.path ?? "/invoicing/export/documents",
    meta: documents8OglKsww6sMeta || {},
    alias: documents8OglKsww6sMeta?.alias || [],
    redirect: documents8OglKsww6sMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/export/documents.vue").then(m => m.default || m)
  },
  {
    name: indexCD7jY1zieiMeta?.name ?? "invoicing-export",
    path: indexCD7jY1zieiMeta?.path ?? "/invoicing/export",
    meta: indexCD7jY1zieiMeta || {},
    alias: indexCD7jY1zieiMeta?.alias || [],
    redirect: indexCD7jY1zieiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/export/index.vue").then(m => m.default || m)
  },
  {
    name: documentsCjcHiabzhOMeta?.name ?? "invoicing-import-documents",
    path: documentsCjcHiabzhOMeta?.path ?? "/invoicing/import/documents",
    meta: documentsCjcHiabzhOMeta || {},
    alias: documentsCjcHiabzhOMeta?.alias || [],
    redirect: documentsCjcHiabzhOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/import/documents.vue").then(m => m.default || m)
  },
  {
    name: indexOwHQ6f38jQMeta?.name ?? "invoicing-import",
    path: indexOwHQ6f38jQMeta?.path ?? "/invoicing/import",
    meta: indexOwHQ6f38jQMeta || {},
    alias: indexOwHQ6f38jQMeta?.alias || [],
    redirect: indexOwHQ6f38jQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexTh4KMj2ldWMeta?.name ?? "invoicing",
    path: indexTh4KMj2ldWMeta?.path ?? "/invoicing",
    meta: indexTh4KMj2ldWMeta || {},
    alias: indexTh4KMj2ldWMeta?.alias || [],
    redirect: indexTh4KMj2ldWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/index.vue").then(m => m.default || m)
  },
  {
    name: indexGyA3frfBmDMeta?.name ?? "invoicing-inventory",
    path: indexGyA3frfBmDMeta?.path ?? "/invoicing/inventory",
    meta: indexGyA3frfBmDMeta || {},
    alias: indexGyA3frfBmDMeta?.alias || [],
    redirect: indexGyA3frfBmDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: product_45categoriesVxjbEJpScWMeta?.name ?? "invoicing-inventory-product-categories",
    path: product_45categoriesVxjbEJpScWMeta?.path ?? "/invoicing/inventory/product-categories",
    meta: product_45categoriesVxjbEJpScWMeta || {},
    alias: product_45categoriesVxjbEJpScWMeta?.alias || [],
    redirect: product_45categoriesVxjbEJpScWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/product-categories.vue").then(m => m.default || m)
  },
  {
    name: product_45sectionsN8TIOWqkI1Meta?.name ?? "invoicing-inventory-product-sections",
    path: product_45sectionsN8TIOWqkI1Meta?.path ?? "/invoicing/inventory/product-sections",
    meta: product_45sectionsN8TIOWqkI1Meta || {},
    alias: product_45sectionsN8TIOWqkI1Meta?.alias || [],
    redirect: product_45sectionsN8TIOWqkI1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/product-sections.vue").then(m => m.default || m)
  },
  {
    name: product_45unitsEnzYe18Mh2Meta?.name ?? "invoicing-inventory-product-units",
    path: product_45unitsEnzYe18Mh2Meta?.path ?? "/invoicing/inventory/product-units",
    meta: product_45unitsEnzYe18Mh2Meta || {},
    alias: product_45unitsEnzYe18Mh2Meta?.alias || [],
    redirect: product_45unitsEnzYe18Mh2Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/product-units.vue").then(m => m.default || m)
  },
  {
    name: productsa6kU7uslH1Meta?.name ?? "invoicing-inventory-products",
    path: productsa6kU7uslH1Meta?.path ?? "/invoicing/inventory/products",
    meta: productsa6kU7uslH1Meta || {},
    alias: productsa6kU7uslH1Meta?.alias || [],
    redirect: productsa6kU7uslH1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/products.vue").then(m => m.default || m)
  },
  {
    name: vat_45ratesVag8VXBqzXMeta?.name ?? "invoicing-inventory-vat-rates",
    path: vat_45ratesVag8VXBqzXMeta?.path ?? "/invoicing/inventory/vat-rates",
    meta: vat_45ratesVag8VXBqzXMeta || {},
    alias: vat_45ratesVag8VXBqzXMeta?.alias || [],
    redirect: vat_45ratesVag8VXBqzXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/inventory/vat-rates.vue").then(m => m.default || m)
  },
  {
    name: editW6xAwDxn7IMeta?.name ?? "invoicing-invoices-id-edit",
    path: editW6xAwDxn7IMeta?.path ?? "/invoicing/invoices/:id()/edit",
    meta: editW6xAwDxn7IMeta || {},
    alias: editW6xAwDxn7IMeta?.alias || [],
    redirect: editW6xAwDxn7IMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/invoices/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createiEil0DwTD8Meta?.name ?? "invoicing-invoices-create",
    path: createiEil0DwTD8Meta?.path ?? "/invoicing/invoices/create",
    meta: createiEil0DwTD8Meta || {},
    alias: createiEil0DwTD8Meta?.alias || [],
    redirect: createiEil0DwTD8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/invoices/create.vue").then(m => m.default || m)
  },
  {
    name: indexhTOKCQAlgKMeta?.name ?? "invoicing-invoices",
    path: indexhTOKCQAlgKMeta?.path ?? "/invoicing/invoices",
    meta: indexhTOKCQAlgKMeta || {},
    alias: indexhTOKCQAlgKMeta?.alias || [],
    redirect: indexhTOKCQAlgKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: edit3FArniALVdMeta?.name ?? "invoicing-order-slips-id-edit",
    path: edit3FArniALVdMeta?.path ?? "/invoicing/order-slips/:id()/edit",
    meta: edit3FArniALVdMeta || {},
    alias: edit3FArniALVdMeta?.alias || [],
    redirect: edit3FArniALVdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/order-slips/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create1FDJjQNowIMeta?.name ?? "invoicing-order-slips-create",
    path: create1FDJjQNowIMeta?.path ?? "/invoicing/order-slips/create",
    meta: create1FDJjQNowIMeta || {},
    alias: create1FDJjQNowIMeta?.alias || [],
    redirect: create1FDJjQNowIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/order-slips/create.vue").then(m => m.default || m)
  },
  {
    name: indexhK5ihVASGVMeta?.name ?? "invoicing-order-slips",
    path: indexhK5ihVASGVMeta?.path ?? "/invoicing/order-slips",
    meta: indexhK5ihVASGVMeta || {},
    alias: indexhK5ihVASGVMeta?.alias || [],
    redirect: indexhK5ihVASGVMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/order-slips/index.vue").then(m => m.default || m)
  },
  {
    name: edit4sIjI7dR1TMeta?.name ?? "invoicing-quotes-id-edit",
    path: edit4sIjI7dR1TMeta?.path ?? "/invoicing/quotes/:id()/edit",
    meta: edit4sIjI7dR1TMeta || {},
    alias: edit4sIjI7dR1TMeta?.alias || [],
    redirect: edit4sIjI7dR1TMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/quotes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createvH3pHKD78jMeta?.name ?? "invoicing-quotes-create",
    path: createvH3pHKD78jMeta?.path ?? "/invoicing/quotes/create",
    meta: createvH3pHKD78jMeta || {},
    alias: createvH3pHKD78jMeta?.alias || [],
    redirect: createvH3pHKD78jMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/quotes/create.vue").then(m => m.default || m)
  },
  {
    name: indexQ8NbMEB6WzMeta?.name ?? "invoicing-quotes",
    path: indexQ8NbMEB6WzMeta?.path ?? "/invoicing/quotes",
    meta: indexQ8NbMEB6WzMeta || {},
    alias: indexQ8NbMEB6WzMeta?.alias || [],
    redirect: indexQ8NbMEB6WzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: settingskX8BxbY2lfMeta?.name ?? undefined,
    path: settingskX8BxbY2lfMeta?.path ?? "/invoicing/settings",
    meta: settingskX8BxbY2lfMeta || {},
    alias: settingskX8BxbY2lfMeta?.alias || [],
    redirect: settingskX8BxbY2lfMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings.vue").then(m => m.default || m),
    children: [
  {
    name: documentssCOgnpuZTtMeta?.name ?? undefined,
    path: documentssCOgnpuZTtMeta?.path ?? "documents",
    meta: documentssCOgnpuZTtMeta || {},
    alias: documentssCOgnpuZTtMeta?.alias || [],
    redirect: documentssCOgnpuZTtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents.vue").then(m => m.default || m),
    children: [
  {
    name: annex_45pdfkJ3zquMWX3Meta?.name ?? "invoicing-settings-documents-annex-pdf",
    path: annex_45pdfkJ3zquMWX3Meta?.path ?? "annex-pdf",
    meta: annex_45pdfkJ3zquMWX3Meta || {},
    alias: annex_45pdfkJ3zquMWX3Meta?.alias || [],
    redirect: annex_45pdfkJ3zquMWX3Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/annex-pdf.vue").then(m => m.default || m)
  },
  {
    name: credit_45notesuNHhIj3OFAMeta?.name ?? "invoicing-settings-documents-credit-notes",
    path: credit_45notesuNHhIj3OFAMeta?.path ?? "credit-notes",
    meta: credit_45notesuNHhIj3OFAMeta || {},
    alias: credit_45notesuNHhIj3OFAMeta?.alias || [],
    redirect: credit_45notesuNHhIj3OFAMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/credit-notes.vue").then(m => m.default || m)
  },
  {
    name: delivery_45slipsYkHSWC0c1qMeta?.name ?? "invoicing-settings-documents-delivery-slips",
    path: delivery_45slipsYkHSWC0c1qMeta?.path ?? "delivery-slips",
    meta: delivery_45slipsYkHSWC0c1qMeta || {},
    alias: delivery_45slipsYkHSWC0c1qMeta?.alias || [],
    redirect: delivery_45slipsYkHSWC0c1qMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/delivery-slips.vue").then(m => m.default || m)
  },
  {
    name: indexpv6QNsXQ43Meta?.name ?? "invoicing-settings-documents",
    path: indexpv6QNsXQ43Meta?.path ?? "",
    meta: indexpv6QNsXQ43Meta || {},
    alias: indexpv6QNsXQ43Meta?.alias || [],
    redirect: indexpv6QNsXQ43Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesz4ivqHixjGMeta?.name ?? "invoicing-settings-documents-invoices",
    path: invoicesz4ivqHixjGMeta?.path ?? "invoices",
    meta: invoicesz4ivqHixjGMeta || {},
    alias: invoicesz4ivqHixjGMeta?.alias || [],
    redirect: invoicesz4ivqHixjGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/invoices.vue").then(m => m.default || m)
  },
  {
    name: order_45slipsI4sWKopZhGMeta?.name ?? "invoicing-settings-documents-order-slips",
    path: order_45slipsI4sWKopZhGMeta?.path ?? "order-slips",
    meta: order_45slipsI4sWKopZhGMeta || {},
    alias: order_45slipsI4sWKopZhGMeta?.alias || [],
    redirect: order_45slipsI4sWKopZhGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/order-slips.vue").then(m => m.default || m)
  },
  {
    name: quotesADPNVJ5sYDMeta?.name ?? "invoicing-settings-documents-quotes",
    path: quotesADPNVJ5sYDMeta?.path ?? "quotes",
    meta: quotesADPNVJ5sYDMeta || {},
    alias: quotesADPNVJ5sYDMeta?.alias || [],
    redirect: quotesADPNVJ5sYDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/documents/quotes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexEZ16Z4VdlHMeta?.name ?? "invoicing-settings",
    path: indexEZ16Z4VdlHMeta?.path ?? "",
    meta: indexEZ16Z4VdlHMeta || {},
    alias: indexEZ16Z4VdlHMeta?.alias || [],
    redirect: indexEZ16Z4VdlHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsAtoi33PqCQMeta?.name ?? undefined,
    path: notificationsAtoi33PqCQMeta?.path ?? "notifications",
    meta: notificationsAtoi33PqCQMeta || {},
    alias: notificationsAtoi33PqCQMeta?.alias || [],
    redirect: notificationsAtoi33PqCQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/notifications.vue").then(m => m.default || m),
    children: [
  {
    name: documentsftlN68qBj4Meta?.name ?? "invoicing-settings-notifications-documents",
    path: documentsftlN68qBj4Meta?.path ?? "documents",
    meta: documentsftlN68qBj4Meta || {},
    alias: documentsftlN68qBj4Meta?.alias || [],
    redirect: documentsftlN68qBj4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/notifications/documents.vue").then(m => m.default || m)
  },
  {
    name: indexoQtEmQWzlhMeta?.name ?? "invoicing-settings-notifications",
    path: indexoQtEmQWzlhMeta?.path ?? "",
    meta: indexoQtEmQWzlhMeta || {},
    alias: indexoQtEmQWzlhMeta?.alias || [],
    redirect: indexoQtEmQWzlhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45remindersxh3SmnIjIGMeta?.name ?? "invoicing-settings-notifications-payment-reminders",
    path: payment_45remindersxh3SmnIjIGMeta?.path ?? "payment-reminders",
    meta: payment_45remindersxh3SmnIjIGMeta || {},
    alias: payment_45remindersxh3SmnIjIGMeta?.alias || [],
    redirect: payment_45remindersxh3SmnIjIGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/notifications/payment-reminders.vue").then(m => m.default || m)
  }
]
  },
  {
    name: templates7kE5N9xGxIMeta?.name ?? "invoicing-settings-templates",
    path: templates7kE5N9xGxIMeta?.path ?? "templates",
    meta: templates7kE5N9xGxIMeta || {},
    alias: templates7kE5N9xGxIMeta?.alias || [],
    redirect: templates7kE5N9xGxIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/settings/templates.vue").then(m => m.default || m)
  }
]
  },
  {
    name: statisticsYiREiy5UFfMeta?.name ?? "invoicing-statistics",
    path: statisticsYiREiy5UFfMeta?.path ?? "/invoicing/statistics",
    meta: statisticsYiREiy5UFfMeta || {},
    alias: statisticsYiREiy5UFfMeta?.alias || [],
    redirect: statisticsYiREiy5UFfMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/invoicing/statistics.vue").then(m => m.default || m)
  },
  {
    name: indexCSx38ydJwoMeta?.name ?? "onboarding",
    path: indexCSx38ydJwoMeta?.path ?? "/onboarding",
    meta: indexCSx38ydJwoMeta || {},
    alias: indexCSx38ydJwoMeta?.alias || [],
    redirect: indexCSx38ydJwoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: indexe7nOpqdrhmMeta?.name ?? "planning",
    path: indexe7nOpqdrhmMeta?.path ?? "/planning",
    meta: indexe7nOpqdrhmMeta || {},
    alias: indexe7nOpqdrhmMeta?.alias || [],
    redirect: indexe7nOpqdrhmMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/planning/index.vue").then(m => m.default || m)
  },
  {
    name: indexSsD3jPanxrMeta?.name ?? "redirect",
    path: indexSsD3jPanxrMeta?.path ?? "/redirect",
    meta: indexSsD3jPanxrMeta || {},
    alias: indexSsD3jPanxrMeta?.alias || [],
    redirect: indexSsD3jPanxrMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45informationsjY97flgxNvMeta?.name ?? "settings-company-contact-informations",
    path: contact_45informationsjY97flgxNvMeta?.path ?? "/settings/company/contact-informations",
    meta: contact_45informationsjY97flgxNvMeta || {},
    alias: contact_45informationsjY97flgxNvMeta?.alias || [],
    redirect: contact_45informationsjY97flgxNvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/company/contact-informations.vue").then(m => m.default || m)
  },
  {
    name: indexRgXymITI7LMeta?.name ?? "settings-company",
    path: indexRgXymITI7LMeta?.path ?? "/settings/company",
    meta: indexRgXymITI7LMeta || {},
    alias: indexRgXymITI7LMeta?.alias || [],
    redirect: indexRgXymITI7LMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/company/index.vue").then(m => m.default || m)
  },
  {
    name: informationsqsYfbU20dJMeta?.name ?? "settings-company-informations",
    path: informationsqsYfbU20dJMeta?.path ?? "/settings/company/informations",
    meta: informationsqsYfbU20dJMeta || {},
    alias: informationsqsYfbU20dJMeta?.alias || [],
    redirect: informationsqsYfbU20dJMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/company/informations.vue").then(m => m.default || m)
  },
  {
    name: logocxzI0x22g2Meta?.name ?? "settings-company-logo",
    path: logocxzI0x22g2Meta?.path ?? "/settings/company/logo",
    meta: logocxzI0x22g2Meta || {},
    alias: logocxzI0x22g2Meta?.alias || [],
    redirect: logocxzI0x22g2Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/company/logo.vue").then(m => m.default || m)
  },
  {
    name: time_45activitiesNLFoiB2iNaMeta?.name ?? "settings-company-time-activities",
    path: time_45activitiesNLFoiB2iNaMeta?.path ?? "/settings/company/time-activities",
    meta: time_45activitiesNLFoiB2iNaMeta || {},
    alias: time_45activitiesNLFoiB2iNaMeta?.alias || [],
    redirect: time_45activitiesNLFoiB2iNaMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/company/time-activities.vue").then(m => m.default || m)
  },
  {
    name: indexNDbLfjd3sGMeta?.name ?? "settings-outbox",
    path: indexNDbLfjd3sGMeta?.path ?? "/settings/outbox",
    meta: indexNDbLfjd3sGMeta || {},
    alias: indexNDbLfjd3sGMeta?.alias || [],
    redirect: indexNDbLfjd3sGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/outbox/index.vue").then(m => m.default || m)
  },
  {
    name: logspL7xjKWCP4Meta?.name ?? "settings-outbox-logs",
    path: logspL7xjKWCP4Meta?.path ?? "/settings/outbox/logs",
    meta: logspL7xjKWCP4Meta || {},
    alias: logspL7xjKWCP4Meta?.alias || [],
    redirect: logspL7xjKWCP4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/outbox/logs.vue").then(m => m.default || m)
  },
  {
    name: settingsjcb1VAKR04Meta?.name ?? "settings-outbox-settings",
    path: settingsjcb1VAKR04Meta?.path ?? "/settings/outbox/settings",
    meta: settingsjcb1VAKR04Meta || {},
    alias: settingsjcb1VAKR04Meta?.alias || [],
    redirect: settingsjcb1VAKR04Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/outbox/settings.vue").then(m => m.default || m)
  },
  {
    name: avatarv0hEfZlEkXMeta?.name ?? "settings-profile-avatar",
    path: avatarv0hEfZlEkXMeta?.path ?? "/settings/profile/avatar",
    meta: avatarv0hEfZlEkXMeta || {},
    alias: avatarv0hEfZlEkXMeta?.alias || [],
    redirect: avatarv0hEfZlEkXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/profile/avatar.vue").then(m => m.default || m)
  },
  {
    name: indexPiLoyVOKUxMeta?.name ?? "settings-profile",
    path: indexPiLoyVOKUxMeta?.path ?? "/settings/profile",
    meta: indexPiLoyVOKUxMeta || {},
    alias: indexPiLoyVOKUxMeta?.alias || [],
    redirect: indexPiLoyVOKUxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: informationsIieTmrUaUFMeta?.name ?? "settings-profile-informations",
    path: informationsIieTmrUaUFMeta?.path ?? "/settings/profile/informations",
    meta: informationsIieTmrUaUFMeta || {},
    alias: informationsIieTmrUaUFMeta?.alias || [],
    redirect: informationsIieTmrUaUFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/profile/informations.vue").then(m => m.default || m)
  },
  {
    name: passwordmbF8skaxCBMeta?.name ?? "settings-profile-password",
    path: passwordmbF8skaxCBMeta?.path ?? "/settings/profile/password",
    meta: passwordmbF8skaxCBMeta || {},
    alias: passwordmbF8skaxCBMeta?.alias || [],
    redirect: passwordmbF8skaxCBMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/profile/password.vue").then(m => m.default || m)
  },
  {
    name: creditszgMmwAVxGdMeta?.name ?? "settings-subscription-credits",
    path: creditszgMmwAVxGdMeta?.path ?? "/settings/subscription/credits",
    meta: creditszgMmwAVxGdMeta || {},
    alias: creditszgMmwAVxGdMeta?.alias || [],
    redirect: creditszgMmwAVxGdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/credits.vue").then(m => m.default || m)
  },
  {
    name: indexUSrknHIjvDMeta?.name ?? "settings-subscription",
    path: indexUSrknHIjvDMeta?.path ?? "/settings/subscription",
    meta: indexUSrknHIjvDMeta || {},
    alias: indexUSrknHIjvDMeta?.alias || [],
    redirect: indexUSrknHIjvDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: invoices030dy7WnRbMeta?.name ?? "settings-subscription-invoices",
    path: invoices030dy7WnRbMeta?.path ?? "/settings/subscription/invoices",
    meta: invoices030dy7WnRbMeta || {},
    alias: invoices030dy7WnRbMeta?.alias || [],
    redirect: invoices030dy7WnRbMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/invoices.vue").then(m => m.default || m)
  },
  {
    name: planSRA4un6QBQMeta?.name ?? "settings-subscription-plan",
    path: planSRA4un6QBQMeta?.path ?? "/settings/subscription/plan",
    meta: planSRA4un6QBQMeta || {},
    alias: planSRA4un6QBQMeta?.alias || [],
    redirect: planSRA4un6QBQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/plan.vue").then(m => m.default || m)
  },
  {
    name: trialTF5cMdPXx1Meta?.name ?? "settings-subscription-trial",
    path: trialTF5cMdPXx1Meta?.path ?? "/settings/subscription/trial",
    meta: trialTF5cMdPXx1Meta || {},
    alias: trialTF5cMdPXx1Meta?.alias || [],
    redirect: trialTF5cMdPXx1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/trial.vue").then(m => m.default || m)
  },
  {
    name: users_45spotso9nHWnpi0IMeta?.name ?? "settings-subscription-users-spots",
    path: users_45spotso9nHWnpi0IMeta?.path ?? "/settings/subscription/users-spots",
    meta: users_45spotso9nHWnpi0IMeta || {},
    alias: users_45spotso9nHWnpi0IMeta?.alias || [],
    redirect: users_45spotso9nHWnpi0IMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/subscription/users-spots.vue").then(m => m.default || m)
  },
  {
    name: index0RFa8FiYGwMeta?.name ?? "settings-terms-of-service",
    path: index0RFa8FiYGwMeta?.path ?? "/settings/terms-of-service",
    meta: index0RFa8FiYGwMeta || {},
    alias: index0RFa8FiYGwMeta?.alias || [],
    redirect: index0RFa8FiYGwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93M2jjnvBI9MMeta?.name ?? "settings-users-id",
    path: _91id_93M2jjnvBI9MMeta?.path ?? "/settings/users/:id()",
    meta: _91id_93M2jjnvBI9MMeta || {},
    alias: _91id_93M2jjnvBI9MMeta?.alias || [],
    redirect: _91id_93M2jjnvBI9MMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXu5KbXbpH1Meta?.name ?? "settings-users",
    path: indexXu5KbXbpH1Meta?.path ?? "/settings/users",
    meta: indexXu5KbXbpH1Meta || {},
    alias: indexXu5KbXbpH1Meta?.alias || [],
    redirect: indexXu5KbXbpH1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: inviteBHz5kTKFLGMeta?.name ?? "settings-users-invite",
    path: inviteBHz5kTKFLGMeta?.path ?? "/settings/users/invite",
    meta: inviteBHz5kTKFLGMeta || {},
    alias: inviteBHz5kTKFLGMeta?.alias || [],
    redirect: inviteBHz5kTKFLGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/users/invite.vue").then(m => m.default || m)
  },
  {
    name: signed_45documentRTdi9XGOffMeta?.name ?? "signed-document",
    path: signed_45documentRTdi9XGOffMeta?.path ?? "/signed-document",
    meta: signed_45documentRTdi9XGOffMeta || {},
    alias: signed_45documentRTdi9XGOffMeta?.alias || [],
    redirect: signed_45documentRTdi9XGOffMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/signed-document.vue").then(m => m.default || m)
  },
  {
    name: switch_45tenant1FNuV8MzJDMeta?.name ?? "switch-tenant",
    path: switch_45tenant1FNuV8MzJDMeta?.path ?? "/switch-tenant",
    meta: switch_45tenant1FNuV8MzJDMeta || {},
    alias: switch_45tenant1FNuV8MzJDMeta?.alias || [],
    redirect: switch_45tenant1FNuV8MzJDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/switch-tenant.vue").then(m => m.default || m)
  },
  {
    name: indexpHxEomLDMNMeta?.name ?? "task",
    path: indexpHxEomLDMNMeta?.path ?? "/task",
    meta: indexpHxEomLDMNMeta || {},
    alias: indexpHxEomLDMNMeta?.alias || [],
    redirect: indexpHxEomLDMNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/task/index.vue").then(m => m.default || m)
  },
  {
    name: indexDsSxFIQFZDMeta?.name ?? "timesheet",
    path: indexDsSxFIQFZDMeta?.path ?? "/timesheet",
    meta: indexDsSxFIQFZDMeta || {},
    alias: indexDsSxFIQFZDMeta?.alias || [],
    redirect: indexDsSxFIQFZDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/timesheet/index.vue").then(m => m.default || m)
  },
  {
    name: indexFKB6mfZqwjMeta?.name ?? "worksite",
    path: indexFKB6mfZqwjMeta?.path ?? "/worksite",
    meta: indexFKB6mfZqwjMeta || {},
    alias: indexFKB6mfZqwjMeta?.alias || [],
    redirect: indexFKB6mfZqwjMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/index.vue").then(m => m.default || m)
  },
  {
    name: planningjoDwLkCKtzMeta?.name ?? undefined,
    path: planningjoDwLkCKtzMeta?.path ?? "/worksite/planning",
    meta: planningjoDwLkCKtzMeta || {},
    alias: planningjoDwLkCKtzMeta?.alias || [],
    redirect: planningjoDwLkCKtzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/planning.vue").then(m => m.default || m),
    children: [
  {
    name: indexO9QXRgvd0tMeta?.name ?? "worksite-planning",
    path: indexO9QXRgvd0tMeta?.path ?? "",
    meta: indexO9QXRgvd0tMeta || {},
    alias: indexO9QXRgvd0tMeta?.alias || [],
    redirect: indexO9QXRgvd0tMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/planning/index.vue").then(m => m.default || m)
  },
  {
    name: worksite2gnggKcLlPMeta?.name ?? "worksite-planning-worksite",
    path: worksite2gnggKcLlPMeta?.path ?? "worksite",
    meta: worksite2gnggKcLlPMeta || {},
    alias: worksite2gnggKcLlPMeta?.alias || [],
    redirect: worksite2gnggKcLlPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/planning/worksite.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profitabilityJzeo7J3W89Meta?.name ?? "worksite-profitability",
    path: profitabilityJzeo7J3W89Meta?.path ?? "/worksite/profitability",
    meta: profitabilityJzeo7J3W89Meta || {},
    alias: profitabilityJzeo7J3W89Meta?.alias || [],
    redirect: profitabilityJzeo7J3W89Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/profitability.vue").then(m => m.default || m)
  },
  {
    name: worksite_45closure_45reportAGqnbJ5PlyMeta?.name ?? "worksite-worksite-closure-report",
    path: worksite_45closure_45reportAGqnbJ5PlyMeta?.path ?? "/worksite/worksite-closure-report",
    meta: worksite_45closure_45reportAGqnbJ5PlyMeta || {},
    alias: worksite_45closure_45reportAGqnbJ5PlyMeta?.alias || [],
    redirect: worksite_45closure_45reportAGqnbJ5PlyMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite-closure-report.vue").then(m => m.default || m)
  },
  {
    name: indexK2reUkBHHAMeta?.name ?? "worksite-worksite-closure-reports",
    path: indexK2reUkBHHAMeta?.path ?? "/worksite/worksite-closure-reports",
    meta: indexK2reUkBHHAMeta || {},
    alias: indexK2reUkBHHAMeta?.alias || [],
    redirect: indexK2reUkBHHAMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite-closure-reports/index.vue").then(m => m.default || m)
  },
  {
    name: worksite_45progress_45billingsKIhEFQ6EYxMeta?.name ?? "worksite-worksite-progress-billings",
    path: worksite_45progress_45billingsKIhEFQ6EYxMeta?.path ?? "/worksite/worksite-progress-billings",
    meta: worksite_45progress_45billingsKIhEFQ6EYxMeta || {},
    alias: worksite_45progress_45billingsKIhEFQ6EYxMeta?.alias || [],
    redirect: worksite_45progress_45billingsKIhEFQ6EYxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite-progress-billings.vue").then(m => m.default || m)
  },
  {
    name: _91id_939IIjKoADdpMeta?.name ?? undefined,
    path: _91id_939IIjKoADdpMeta?.path ?? "/worksite/worksite/:id()",
    meta: _91id_939IIjKoADdpMeta || {},
    alias: _91id_939IIjKoADdpMeta?.alias || [],
    redirect: _91id_939IIjKoADdpMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id].vue").then(m => m.default || m),
    children: [
  {
    name: documentspOZeplGFhXMeta?.name ?? "worksite-worksite-id-documents",
    path: documentspOZeplGFhXMeta?.path ?? "documents",
    meta: documentspOZeplGFhXMeta || {},
    alias: documentspOZeplGFhXMeta?.alias || [],
    redirect: documentspOZeplGFhXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/documents.vue").then(m => m.default || m)
  },
  {
    name: indexUvMTKe2cIUMeta?.name ?? "worksite-worksite-id",
    path: indexUvMTKe2cIUMeta?.path ?? "",
    meta: indexUvMTKe2cIUMeta || {},
    alias: indexUvMTKe2cIUMeta?.alias || [],
    redirect: indexUvMTKe2cIUMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: informationsEjfiGk8tkDMeta?.name ?? "worksite-worksite-id-informations",
    path: informationsEjfiGk8tkDMeta?.path ?? "informations",
    meta: informationsEjfiGk8tkDMeta || {},
    alias: informationsEjfiGk8tkDMeta?.alias || [],
    redirect: informationsEjfiGk8tkDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: profitability2VRWD1bV9gMeta?.name ?? "worksite-worksite-id-profitability",
    path: profitability2VRWD1bV9gMeta?.path ?? "profitability",
    meta: profitability2VRWD1bV9gMeta || {},
    alias: profitability2VRWD1bV9gMeta?.alias || [],
    redirect: profitability2VRWD1bV9gMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/profitability.vue").then(m => m.default || m)
  },
  {
    name: edit45KRA0Gz0hMeta?.name ?? "worksite-worksite-id-progress-billings-progressBillingId-edit",
    path: edit45KRA0Gz0hMeta?.path ?? "progress-billings/:progressBillingId()/edit",
    meta: edit45KRA0Gz0hMeta || {},
    alias: edit45KRA0Gz0hMeta?.alias || [],
    redirect: edit45KRA0Gz0hMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/[progressBillingId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyUT62cJMMMMeta?.name ?? "worksite-worksite-id-progress-billings-progressBillingId",
    path: indexyUT62cJMMMMeta?.path ?? "progress-billings/:progressBillingId()",
    meta: indexyUT62cJMMMMeta || {},
    alias: indexyUT62cJMMMMeta?.alias || [],
    redirect: indexyUT62cJMMMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/[progressBillingId]/index.vue").then(m => m.default || m)
  },
  {
    name: createIofurjNHnTMeta?.name ?? "worksite-worksite-id-progress-billings-create",
    path: createIofurjNHnTMeta?.path ?? "progress-billings/create",
    meta: createIofurjNHnTMeta || {},
    alias: createIofurjNHnTMeta?.alias || [],
    redirect: createIofurjNHnTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/create.vue").then(m => m.default || m)
  },
  {
    name: indexgOKRyjAOFwMeta?.name ?? "worksite-worksite-id-progress-billings",
    path: indexgOKRyjAOFwMeta?.path ?? "progress-billings",
    meta: indexgOKRyjAOFwMeta || {},
    alias: indexgOKRyjAOFwMeta?.alias || [],
    redirect: indexgOKRyjAOFwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/progress-billings/index.vue").then(m => m.default || m)
  },
  {
    name: resourcespkQjZCaveqMeta?.name ?? "worksite-worksite-id-resources",
    path: resourcespkQjZCaveqMeta?.path ?? "resources",
    meta: resourcespkQjZCaveqMeta || {},
    alias: resourcespkQjZCaveqMeta?.alias || [],
    redirect: resourcespkQjZCaveqMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/resources.vue").then(m => m.default || m)
  },
  {
    name: taskspCUmpySvy4Meta?.name ?? "worksite-worksite-id-tasks",
    path: taskspCUmpySvy4Meta?.path ?? "tasks",
    meta: taskspCUmpySvy4Meta || {},
    alias: taskspCUmpySvy4Meta?.alias || [],
    redirect: taskspCUmpySvy4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/tasks.vue").then(m => m.default || m)
  },
  {
    name: time_45sheetsv7N62PXQXGMeta?.name ?? "worksite-worksite-id-time-sheets",
    path: time_45sheetsv7N62PXQXGMeta?.path ?? "time-sheets",
    meta: time_45sheetsv7N62PXQXGMeta || {},
    alias: time_45sheetsv7N62PXQXGMeta?.alias || [],
    redirect: time_45sheetsv7N62PXQXGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/time-sheets.vue").then(m => m.default || m)
  },
  {
    name: timelinevVczYvnEf5Meta?.name ?? "worksite-worksite-id-timeline",
    path: timelinevVczYvnEf5Meta?.path ?? "timeline",
    meta: timelinevVczYvnEf5Meta || {},
    alias: timelinevVczYvnEf5Meta?.alias || [],
    redirect: timelinevVczYvnEf5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/timeline.vue").then(m => m.default || m)
  },
  {
    name: indexniJSrfRoqPMeta?.name ?? "worksite-worksite-id-worksite-closure-reports-closureReport",
    path: indexniJSrfRoqPMeta?.path ?? "worksite-closure-reports/:closureReport()",
    meta: indexniJSrfRoqPMeta || {},
    alias: indexniJSrfRoqPMeta?.alias || [],
    redirect: indexniJSrfRoqPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/worksite-closure-reports/[closureReport]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjvp7Lbs7ZwMeta?.name ?? "worksite-worksite-id-worksite-closure-reports",
    path: indexjvp7Lbs7ZwMeta?.path ?? "worksite-closure-reports",
    meta: indexjvp7Lbs7ZwMeta || {},
    alias: indexjvp7Lbs7ZwMeta?.alias || [],
    redirect: indexjvp7Lbs7ZwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksite/[id]/worksite-closure-reports/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: worksitesD9JURVdQn4Meta?.name ?? "worksite-worksites",
    path: worksitesD9JURVdQn4Meta?.path ?? "/worksite/worksites",
    meta: worksitesD9JURVdQn4Meta || {},
    alias: worksitesD9JURVdQn4Meta?.alias || [],
    redirect: worksitesD9JURVdQn4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/worksite/worksites.vue").then(m => m.default || m)
  },
  {
    name: auth_45switchCmfR8XJR2AMeta?.name ?? "auth-switch",
    path: auth_45switchCmfR8XJR2AMeta?.path ?? "/auth/switch",
    meta: auth_45switchCmfR8XJR2AMeta || {},
    alias: auth_45switchCmfR8XJR2AMeta?.alias || [],
    redirect: auth_45switchCmfR8XJR2AMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/@deegital/nuxt-trustup-io-app-switch/dist/runtime/pages/auth-switch").then(m => m.default || m)
  }
]